import React from "react";
import "./services.css";
import { NavLink } from "react-router-dom";
import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
const Services = () => {
  return (
    <>
      <div className="services">
        <div className="services-header">
          <div className="services-name">
            <h2>WHAT WE OFFER</h2>
          </div>
          <div className="services-head-name">
            <h1>Get rid of unwanted pests!</h1>
          </div>
          <div className="services-head-content">
            <p>
            Best advice on the treatment plan, Experienced and knowledgeable pest control technicians, Use of the latest technology and equipment to get rid of pests.
            </p>
          </div>
        </div>
        <div className="serivecs-row1">
          <div className="services-card">
            <div className="card-details">
              <div className="card-left">
                <div className="card-img">
                  <img src={img1} alt="UCPC-service-img" />
                </div>
              </div>
              <div className="card-right">
                <div className="card-head">
                  <h1>Pest Control</h1>
                </div>
                <div className="card-content">
                  <p>
                  The presence of pests can damage your inventories in multiple ways, as well as pose health and safety risks to your employees, which can disrupt operations and ultimately result in revenue losses.
                  </p>
                </div>
                <div className="card-btn-more btn-grad">
                  <a href="/">Learn more</a>
                </div>
              </div>
            </div>
            <div className="card-details">
              <div className="card-left">
                <div className="card-img">
                  <img src={img2} alt="UCPC-card-img" />
                </div>
              </div>
              <div className="card-right">
                <div className="card-head">
                  <h1>Termite Control</h1>
                </div>
                <div className="card-content">
                  <p>
                  You can find boric acid as the main active ingredient in a large number of termite insecticides available at the store. Boric acid destroys the termites by dehydrating them and shutting their nervous systems down.
                  </p>
                </div>
                <div className="card-btn-more btn-grad">
                  <a href="/">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="services-row2">
          <div className="services-card">
            <div className="card-details">
              <div className="card-left">
                <div className="card-img">
                  <img src={img3} alt="UCPC-card-img-1" />
                </div>
              </div>
              <div className="card-right">
                <div className="card-head">
                  <h1>Bed Bug Control</h1>
                </div>
                <div className="card-content">
                  <p>
                  There are hundreds of home remedies available, but none work as well as professional pest control companies. Bed bugs are small parasites that grow up to the size of an apple seed.
                  </p>
                </div>
                <div className="card-btn-more btn-grad">
                  <a href="/">Learn more</a>
                </div>
              </div>
            </div>
            <div className="card-details">
              <div className="card-left">
                <div className="card-img">
                  <img src={img4} alt="UCPC-card-img-2" />
                </div>
              </div>
              <div className="card-right">
                <div className="card-head">
                  <h1>Mosquito Control</h1>
                </div>
                <div className="card-content">
                  <p>
                  When it comes to natural predators, I would have to say that mosquitofish is by far the most effective
                  </p>
                </div>
                <div className="card-btn-more btn-grad">
                  <a href="/">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <div className="services-more button-86">
        <NavLink to="/services" className="list ">
             More
            </NavLink>
        </div>
      </div>
    </>
  );
};

export default Services;
