import React from "react";
import "./features.css";
import rodent from './rodent.png';
import img2 from '../services/img2.jpg';
import bedbug from './bedbug.png';
import commercial from './commercial.png';
import ant from './ant.png';
import resturant from './resturant.png';
import cockroach from './cockroach.png';
import bee from './bee.png';







const Features = () => {
  return (
    <div className="features">
      <div className="features-left">
        <div className="features-left-content">
          <h2>WHY CHOOSE US</h2>
          <h1>Pest Control services</h1>
          <p>
          We are a prominent firm for providing Pest Control Service. We offer customized pest control service for all clients’ needs.
          </p>
          <div className="feature-more-btn btn-grad">
            <a href="/"> <i class="fa-solid fa-phone-volume"></i>&nbsp;Book NOw</a>
          </div>
        </div>
      </div>
      <div className="features-right">
        <div className="features-right-box1">
          <div className="box1">
            <img src={img2} alt="feature-img1" />
            <h1>Termite Control</h1>
          </div>
          <div className="box1">
          <img src={rodent} alt="feature-img1" />
            <h1>Rodent Control</h1>
          </div>
        </div>
        <div className="features-right-box2">
          <div className="features-right-box1">
            <div className="box1">
            <img src={commercial} alt="feature-img1" />
            <h1>Commercial Control</h1>
            </div>
            <div className="box1">
            <img src={resturant} alt="feature-img1" />
            <h1>Restaurant Control</h1>
            </div>
          </div>
        </div>
        <div className="features-right-box2">
          <div className="features-right-box1">
            <div className="box1">
            <img src={bedbug} alt="feature-img1" />
            <h1>Bed Bug Control</h1>
            </div>
            <div className="box1">
            <img src={cockroach} alt="feature-img1" />
            <h1>Cockroach Control</h1>
            </div>
          </div>
        </div>
        <div className="features-right-box2">
          <div className="features-right-box1">
            <div className="box1">
            <img src={ant} alt="feature-img1" />
            <h1>Ant Control</h1>
            </div>
            <div className="box1">
            <img src={bee} alt="feature-img1" />
            <h1>Beehive Removal</h1>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Features;
