import "./services.css";
import Costumer from "../costumer/Costumer";
import NeedHelp from "../needhelp/NeedHelp";
import "./services.css";
import page_img from "./service-page.gif";
import asked from "./asked.gif";
import Footer from "../footer/Footer";
import rodent from "../features/rodent.png";
import img2 from "../services/img2.jpg";
import bedbug from "../features/bedbug.png";
import commercial from "../features/commercial.png";
import ant from "../features/ant.png";
import resturant from "../features/resturant.png";
import cockroach from "../features/cockroach.png";
import bee from "../features/bee.png";
import Loaction from "../location/Loaction";
const ServicesPages = () => {
  return (
    <>
      <div className="services-page">
        <div className="services-header">
          <div className="services-name">
            <h2>WHAT WE OFFER</h2>
          </div>
          <div className="services-head-name">
            <h1>Get rid of unwanted pests!</h1>
          </div>
          <div className="services-head-content">
            <p>
              We are a prominent firm for providing Pest Control Service. We
              offer customized pest control service for all clients’ needs.
            </p>
          </div>
        </div>

        {/* -----------service-page-1-start---------------- */}
        <div className="service-page-1">
          <div className="services-page-left">
            <div className="services-page-left-head">
              <h1>Pest Control</h1>
            </div>
            <div className="services-page-left-para">
              <p>
                With sincerity of our personnel, we have been capable of
                presenting to our clients highly reliable and distinctive Pest
                Control Service. Owing to their timely implementation and
                completion, reliability and flexibility, these provided services
                are broadly acclaimed and treasured all over the market.
              </p>
            </div>
            <div className="services-page-left-btn btn-grad">
              <a href="/contact"><i class="fa-solid fa-phone-volume"></i>&nbsp;Book Now</a>
            </div>
          </div>
          <div className="services-page-right">
            <div className="services-page-right-img">
              <img src={page_img} alt="page-img" />
            </div>
          </div>
        </div>
        {/* --------------service-page-1-end---------------- */}

        {/* ---------------------service-page-description-start------ */}
        <div className="service-page-description-bg-color">
          <div className="service-page-description">
            <div className="service-page-description-left">
              <div className="features-right">
                <div className="features-right-box1">
                  <div className="box1">
                    <img src={img2} alt="feature-img1" />
                    <h1>Termite Control</h1>
                  </div>
                  <div className="box1">
                    <img src={rodent} alt="feature-img1" />
                    <h1>Rodent Control</h1>
                  </div>
                </div>
                <div className="features-right-box2">
                  <div className="features-right-box1">
                    <div className="box1">
                      <img src={commercial} alt="feature-img1" />
                      <h1>Commercial Control</h1>
                    </div>
                    <div className="box1">
                      <img src={resturant} alt="feature-img1" />
                      <h1>Restaurant Control</h1>
                    </div>
                  </div>
                </div>
                <div className="features-right-box2">
                  <div className="features-right-box1">
                    <div className="box1">
                      <img src={bedbug} alt="feature-img1" />
                      <h1>Bed Bug Control</h1>
                    </div>
                    <div className="box1">
                      <img src={cockroach} alt="feature-img1" />
                      <h1>Cockroach Control</h1>
                    </div>
                  </div>
                </div>
                <div className="features-right-box2">
                  <div className="features-right-box1">
                    <div className="box1">
                      <img src={ant} alt="feature-img1" />
                      <h1>Ant Control</h1>
                    </div>
                    <div className="box1">
                      <img src={bee} alt="feature-img1" />
                      <h1>Beehive Removal</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------service-page-description-end------ */}

        {/* ---------------service-page-common-question-start------------ */}
        <div className="service-page-common-question">
          <div className="service-page-common-img">
            <img src={asked} alt="service-page-Common-img" />
          </div>
          {/* <div className="common-head-name1">
            <h2>COMMON QUESTIONS</h2>
          </div> */}
          <div className="common-head-name2">
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="common-head-name3">
            <p>Feel Free To Drop Us A Message</p>
          </div>

          <div className="dropdown-item btn-grad">
            <a href="/contact">Get touch</a>
          </div>
        </div>

        {/* ---------------service-page-common-question-start------------ */}
      </div>
      &nbsp;
      <Loaction/>
      <NeedHelp />
      <Footer />
    </>
  );
};

export default ServicesPages;
