import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.css";
import whatsapp from "./whatsapp.png";
const Footer = () => {
  return (
    <>
      <div className="footer-all-details">
        <div className="footer">
          <div className="footer-col1">
            <div className="col1-name">
              <h1>Universal Consultant Hi-Care</h1>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor,
                repellendus.
              </p>
            </div>
          </div>
          <div className="footer-col2">
            <div className="col2-name">
              <p>Our Serives</p>

              <li>
                <NavLink to="/about"> About us</NavLink>
              </li>
              <li>
                <NavLink to="/services">Our work</NavLink>
              </li>
              <li>
                <NavLink to="/about"> Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Help center</NavLink>
              </li>
            </div>
          </div>
          <div className="footer-col3">
            <div className="col3-name">
              <p>Company</p>

              <li>
                <a href="/termcondition">Term of condition</a>
              </li>
              <li>
                <a href="/contact">Contact us</a>
              </li>
            </div>
          </div>
          <div className="footer-col4">
            <div className="col4-name">
              <p>Follow us</p>

              <li>
                <a href="/">
                  <i class="fa-brands fa-facebook"></i>Facebook
                </a>
              </li>
              <li>
                <a href="/">
                  <i class="fa-brands fa-linkedin"></i>Linkedin
                </a>
              </li>
              <li>
                <a href="/">
                  <i class="fa-brands fa-square-instagram"></i>Instagram
                </a>
              </li>
              <li>
                <a href="/">
                  <i class="fa-brands fa-square-whatsapp"></i>Whatsapp
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-make-name">
        <div className="copyright">
          <p>Copyright &#169; 2023 Universal Consultant Hi Care</p>
        </div>
        <div className="made-name">
          <a
            href="https://worldfreepage.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>
              Made by <i class="fa-solid fa-heart"></i> India
            </p>
          </a>
        </div>
      </div>
      <div className="whatsapp">
        <a href="https://wa.me/919971539820">
          <img src={whatsapp} alt="whatsapp-icons" />
        </a>
      </div>
    </>
  );
};

export default Footer;
