import React from 'react'
import "./srcolltop.css";
const ScrollTop = () => {

const scrollbtn = () =>{
  window.scrollTo({top:0,left:0,behavior:'smooth'});
}
  return (
   <div className="scrollbtn" onClick={scrollbtn}>
    <i class="bi bi-arrow-up-circle-fill"></i>
   </div>
  )
}

export default ScrollTop