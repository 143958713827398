import React from "react";
import "./costumer.css";
import img1 from "./feedback.png";
const Costumer = () => {
  return (
    <div className="costumer-text-slide">
      <div className="feedback-heading">
        <h1>OUR HAPPY CUSTOMERS</h1>
        <p>What Our Clients Say About Us</p>
      </div>
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="feedback">
              <div className="feedback-box1">
                <div className="feedback-img">
                  <img src={img1} alt="UCPC-feedback-img" />
                </div>
                <div className="feedback-social-media">
                  <i class="fa-brands fa-facebook"></i>
                  <i class="fa-brands fa-square-instagram"></i>
                  <i class="fa-brands fa-twitter"></i>
                </div>
              </div>
              <div className="feedback-box2">
                <div className="feedback-name">
                  <h1>Pravesh</h1>
                </div>
                <div className="feedback-comment">
                  <p>
                    Love Universal Hi care Pest Control they always notify you
                    in advance when they are coming and always come on time and
                    do a through job and will return if you have any problems
                    with pests.
                  </p>
                </div>
                <div className="feedback-btn-more button-86">
                  {/* <a href="/">Read more</a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className="feedback">
              <div className="feedback-box1">
                <div className="feedback-img">
                  <img src={img1} alt="UCPC-feedback-img-2" />
                </div>
                <div className="feedback-social-media">
                  <i class="fa-brands fa-facebook"></i>
                  <i class="fa-brands fa-square-instagram"></i>
                  <i class="fa-brands fa-twitter"></i>
                </div>
              </div>
              <div className="feedback-box2">
                <div className="feedback-name">
                  <h1>Kamlesh</h1>
                </div>
                <div className="feedback-comment">
                  <p>
                    The staff at Universal HI Care Pest Control is always friendly and helpful when we need to deal with them. We have an annual service and if we run into any additional issues they are quick to respond to our needs. Very happy to have them on our team.
                  </p>
                </div>
                <div className="feedback-btn-more button-86">
                  {/* <a href="/">Read more</a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className="feedback">
              <div className="feedback-box1">
                <div className="feedback-img">
                  <img src={img1} alt="UCPC-feedback-img-2" />
                </div>
                <div className="feedback-social-media">
                  <i class="fa-brands fa-facebook"></i>
                  <i class="fa-brands fa-square-instagram"></i>
                  <i class="fa-brands fa-twitter"></i>
                </div>
              </div>
              <div className="feedback-box2">
                <div className="feedback-name">
                  <h1>Santosh</h1>
                </div>
                <div className="feedback-comment">
                  <p>
                  Great company always on time! I live in the woods and since having Universal HI Care Pest Control I no longer have field mice in my garage.
                  </p>
                </div>
                <div className="feedback-btn-more button-86">
                  {/* <a href="/">Read more</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Costumer;
