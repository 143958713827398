import React, { useEffect, useState } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './Home';
import'./index.css'
import ScrollTop from './scroll/ScrollTop';
import { Header } from './component/header/Header';
import ServicesPages from './component/services/ServicesPages';
import Contact from './component/contact/Contact';
// import About from './component/about_page/About';
import { ScaleLoader } from 'react-spinners';
import Termconditon from './component/footer/Termconditon';
import AboutNewpage from './component/about_page/AboutNewpage';
import Loaction from './component/location/Loaction';

function App() {
  const [loading,seloading]=useState(true);
  useEffect(()=>{
    seloading(true);
    setTimeout(()=>{
      seloading(false);
    },2000);
  },[]);
  return (
   <>
   <div className='function'>

   {
    loading ? (
      <ScaleLoader
       size={30} color={"#F37A24"} loading={loading}/>
    ) :
   
   <BrowserRouter>
   <ScrollTop/>
   <Header/>
   <Routes>
    <Route exact path='/' element={<Home/>}/>
    <Route exact path='/about' element={<AboutNewpage/>}/>
    <Route exact path='/location' element={<Loaction/>}/>
    <Route exact path='/services' element={<ServicesPages/>}/>
    <Route exact path='/contact' element={<Contact/> }/>
    <Route exact path='/termcondition' element={<Termconditon/> }/>
   </Routes>
   </BrowserRouter>
   }  
   </div>
   </>
  );
}

export default App;
