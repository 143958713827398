import React from "react";
import "./about_page.css";
// import AnimatedText from 'react-animated-text-content';
const About = () => {
  return (
    <>
      <div className="about-page">
        <div className="about-page-left">
          <h1>WELCOME TO UCPC</h1>
          <h4>Universal consultant Hi-Care</h4>
          <div className="about-page-effect">
            <h2>
              Pest Control
            </h2>
          </div>
          <div className="about-page-left-content">
            <p>
              Don't Worry We Will Help You!
              <span>
          
                <br />
                Fastest Pest Control System Ever
              </span>
            </p>
          </div>
          <div className="button-86 chat-btn">
            <a href="tel:9971539820"><i class="fa-solid fa-phone-volume"></i>&nbsp;Book Now</a>              
          </div>
        </div>
      </div>
      <div className="about-footer">
        <div className="about-page-footer-left">
          <h2>Need Help?</h2>
          {/* <h1>Contact Person</h1> */}
          <h1> <i class="fa-solid fa-phone-volume"></i>&nbsp;<a href="tel:+91-9971539820">+91 9971539820</a> </h1>
          <h2>Sunday-Friday (08:50 AM - 05:00 PM)</h2>
        </div>
        <div className="about-page-footer-right">
          <h2>GET PROTECT FROM PEST</h2>
          <h1>
            Let's start scheduling home.
            <span>
              <br /> visits.
            </span>
          </h1>
          <div className=" button-86 chat-btn">
            <a href="/contact">Chat-us</a>
          </div>
        </div>
      </div>
      <div className="about-page-content">
        <div className="content1">
          <div className="logo">
            <i class="fa-solid fa-virus-slash"></i>
          </div>
          <h1>FREE HOME INSPECTION</h1>
          <p>
            Eco friendly technologies is also used to describe a class of
            electronic devices that can promote and help to pest control
          </p>
        </div>
        <div className="content1">
          <div className="logo">
            <i class="fa-solid fa-virus-slash"></i>
          </div>
          <h1>LICENSED & PROTECTED</h1>
          <p>
            Eco friendly technologies is also used to describe a class of
            electronic devices that can promote and help to pest control
          </p>
        </div>
        <div className="content1">
          <div className="logo">
            <i class="fa-solid fa-spaghetti-monster-flying"></i>
          </div>
          <h1>FAST PEST REMOVAL</h1>
          <p>
            Eco friendly technologies is also used to describe a class of
            electronic devices that can promote and help to pest control
          </p>
        </div>
      </div>
      <div className="content-overlap">
        <div className="overlap1">
          <div className="overlap-logo">
            <i class="fa-solid fa-star"></i>
          </div>
          <h1>5 STAR PROTECTION</h1>
          <p>
            We provide the 5 star protection plan guarantees you stay safe from
            pest without hassle. Saves your money.
          </p>
        </div>
        <div className="overlap2">
          <div className="overlap-logo">
            <i class="fa-solid fa-book-bookmark"></i>
          </div>
          <h1>PESTS LIBRARY</h1>
          <p>
            Certain pests can be very dangerous and even deadly to humans and
            pets. get more information with our pest library.
          </p>
        </div>
        <div className="overlap3">
          <div className="overlap-logo">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <h1>FREE INSPECTION</h1>
          <p>
            Schedule a Complimentary inspection of your Residential or
            Commercial place and effectively rid your life of pests!
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
