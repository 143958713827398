import React from "react";
import "./contact.css";
import Footer from "../footer/Footer";
import contactbg from "./contactimg1.jpg";
import location from "./location.png";
import email from "./email.png";
import call from "./call.png";
import formimg from "./contact-left.gif";
// import  './contact.js';

import contact_name_img from "./contact-head.gif";
const Contact = () => {
  return (
    <>
      <div className="contact-page">
        <div className="contact-bg">
          <img src={contactbg} alt="UCPC-bg-img" />
        </div>
        <div className="contact-name">
          <div className="contact-name-img">
            <img src={contact_name_img} alt="UCPC-name-img" />
          </div>
          <h1>Contact-us</h1>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, ut!</p> */}
        </div>

        <div className="contact-touch">
          <div className="contact-touch-left">
            <div className="touch-name">
              <h2>GET IN TOUCH</h2>
            </div>
            <div className="touch-name1">
              <h1>Let's Talk</h1>
              <p>Feel Free To Drop Us A Message</p>
            </div>
            <div className="touch-details">
              <div className="details-1">
                <div className="icons">
                  <img src={location} alt="UCPC-location" />
                </div>
                <div className="icons-name">
                  <h1>Location</h1>
                  <p>
                    C/112, J.J. Colony Khanpur, Dr. Ambedkar Nagar, Sec-2, New
                    Delhi-62
                  </p>
                </div>
              </div>
              <div className="details-2">
                <div className="icons">
                  <img src={email} alt="UCPC-email" />
                </div>
                <div className="icons-name">
                  <a href="mailto:universalconsultanthicare@gmail.com">
                    <h1>Email us</h1>
                    <p>universalconsultanthicare@gmail.com</p>
                  </a>
                </div>
              </div>
              <div className="details-3">
                <div className="icons">
                  <img src={call} alt="UCPC-call-img" />
                </div>
                <div className="icons-name">
                  <a href="tel:9971539820">
                    <h1>Call us</h1>
                    <p>
                      +91-9971539820 <br /> +91-9654262410
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-touch-right">
            <iframe
              title="UCPC-location"
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3505.7721500382636!2d77.22742576506685!3d28.516502182463732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sC%2F112%2C%20J.J.%20Colony%20Khanpur%2C%20Dr.%20Ambedkar%20Nagar%2C%20Sec-2%2C%20New%20Delhi-62!5e0!3m2!1sen!2sin!4v1680369521162!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className="contact-page-details">
          <div className="contact-page-details-box">
            <h2>SEND US A MESSAGE</h2>
            <h1>We Are Here For You</h1>
          </div>

          <div className="contact-page-details-form">
            <div className="contact-page-details-form-left">
              <img src={formimg} alt="form-img" />
            </div>
            <div className="contact-page-details-form-right">
              <form
                action="https://formsubmit.co/d74056ff0272b09155e4180fa310d605"
                method="POST"
              >
                <input
                  name="name"
                  type="text"
                  class="feedback-input"
                  placeholder="Name"
                  required
                />
                <input
                  name="email"
                  type="email"
                  class="feedback-input"
                  placeholder="Email"
                  required
                />
                <textarea
                  name="text"
                  class="feedback-input"
                  placeholder="Comment"
                ></textarea>
                <button type="submit">Submit Form</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
