import React from "react";
import "./profile.css";
import pestimg from "./first.gif";
import pestimg3 from "./second.gif";
import icons1 from './incons1.png'
import icons2 from './icons2.png'

const Profile = () => {
  return (
    <>
      <div className="about-content">
        <div className="about-content-first">
          <div className="about-content-left">
            <h2>WHO WE ARE</h2>
            <h1>The pest control expert you can trust.</h1>
            <p>
              Universal Consultant Hi- Care Pest Control Services in Khanpur,
              Delhi is a recognised in the industry for pest control and
              management services. It was founded in 2014. Initially, this
              enterprise was solely into providing pest control services but
              subsequently it started offering all-encompassing facility
              management services to organisations. Since its inception, it has
              set global standards in pest control and facility management
              services. Striving towards service excellence, this organisation
              ensures the enforcement of guidelines that strictly adhere to all
              rules, regulations and licensing controls. The quality and timely
              services have help them garner a staggering base of retail as well
              as corporate clients.
            </p>
            <div className="discover-more chat-btn button-86">
              <a href="/">Discover-more</a>
            </div>
          </div>
          <div className="about-content-right">
            <img src={pestimg} alt="pest-img-1" />
          </div>
        </div>
        <div className="about-content-second">
          <div className="about-content-second-left">
            <img src={pestimg3} alt="pest-imge-3" />
          </div>
          <div className="about-content-second-right">
            <h2>APPROACH</h2>
            <h1>The pest control expert you can trust.</h1>
            <p>
            If you have a pest infestation, you can suffer from food contamination, health risks, and property damage. You should hire a professional pest control service to get rid of the infestation.
            </p>
            <div className="about-content-box">
              <div className="about-content-second-right-box1">
                <div className="logo">
                  <img src={icons1} alt="icons1" />
                </div>
                <h1>Pest Control</h1>
                <p>
                The presence of pests can damage your inventories in multiple ways, as well as pose health and safety risks to your employees, which can disrupt operations and ultimately result in revenue losses.
                </p>
                <div className="content-box-btn button-86 chat-btn">
                  <a href="/">Learn-more</a>
                </div>
              </div>
              <div className="about-content-second-right-box2">
                <div className="logo">
                  <img src={icons2} alt="icons2" />
                </div>
                <h1>Inspection</h1>
                <p>
                Inspectors then compare their findings with specific regulations and rules. If a machine or equipment fails, inspectors may conduct a failure analysis. 
                </p>
                  <div className="button-86 chat-btn">
                    <a href="/">Learn-more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
