import React from 'react'
import './location.css'
const Loaction = () => {
  return (
    <div className="location">
        <div className="location-name" >
            <h2>OUR LOCATIONS</h2>
          </div>
          <div className="our-location">
            <div className="location-1-line">
                <div className="location-1">
                    <h1>Gurgaon</h1>
                </div>
                <div className="location-1">
                    <h1>Faridabad</h1>
                </div>
                <div className="location-1">
                    <h1>Sonipat</h1>
                </div>
            </div>
            <div className="location-1-line">
                <div className="location-1">
                    <h1>Noida</h1>
                </div>
                <div className="location-1">
                    <h1>Greater-Noida</h1>
                </div>
                <div className="location-1">
                    <h1>New Delhi</h1>
                </div>
            </div>
            <div className="location-1-line">
                <div className="location-1">
                    <h1>South Delhi</h1>
                </div>
                <div className="location-1">
                    <h1>West Delhi</h1>
                </div>
                <div className="location-1">
                    <h1>East Delhi</h1>
                </div>
            </div>
            <div className="location-1-line">
                <div className="location-1">
                    <h1>North Delhi</h1>
                </div>
                <div className="location-1">
                    <h1>All Delhi</h1>
                </div>
                <div className="location-1">
                    <a href="tel:9971539820">
                    <h1>other</h1>
                    </a>
                </div>
            </div>
          </div>
    </div>
  )
}

export default Loaction