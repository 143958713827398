import React from 'react'
import Costumer from './component/costumer/Costumer';
import Count from './component/counternumber/Count';
import Features from './component/features/Features';
import NeedHelp from './component/needhelp/NeedHelp';
import Profile from './component/profile/Profile';
import Services from './component/services/Services';
import Footer from './component/footer/Footer';
import About from './component/about_page/About';
const Home = () => {
  return (
    <>
    
    <About/>
   <Profile/>
   <Services/>
   <Costumer/>
   <Count/>
   <Features/>
   <NeedHelp/>
   <Footer/>
    </>
  )
}

export default Home;