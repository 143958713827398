import React from "react";
import "./needhelp.css";
const NeedHelp = () => {
  return (
    <div className="needhelp-background">
      <div className="needhelp">
        <div className="neehelp-1">
          <div className="needhelp-head">
            <h2>NEED HELP?</h2>
          </div>
          <div className="needhelp-name">
            <h1>We'd love to hear from you.</h1>
          </div>
          <p>
            Frequently asked question,tips and trick.
          </p>
        </div>
        <div className="neehelp-2">
          <div className="needhelp-2-box">
            <div className="needhelp-2-icons">
              <i class="fa-solid fa-headset"></i>
            </div>
            <div className="needhelp-2-name">
              <h1>Contact Sales</h1>
            </div>
            <div className="needhelp-2-para">
              <p>
                Daily offer goes live at all time Contact Now
              </p>
            </div>
            <div className="needhelp-2-btn btn-grad">
              <a href="/contact">Chat us</a>
            </div>
          </div>
          <div className="needhelp-2-box">
            <div className="needhelp-2-icons">
            <i class="fa-solid fa-location-arrow"></i>
            </div>
            <div className="needhelp-2-name">
              <h1>Send Ticket</h1>
            </div>
            <div className="needhelp-2-para">
              <p>
                Have some Questions? Send Now!
              </p>
            </div>
            <div className="needhelp-2-btn btn-grad">
              <a href="/contact">Send Ticket</a>
            </div>
          </div>
          <div className="needhelp-2-box">
            <div className="needhelp-2-icons">
            <i class="fa-regular fa-id-card"></i>
            </div>
            <div className="needhelp-2-name">
              <h1>Contact Us</h1>
            </div>
            <div className="needhelp-2-para">
              <p>
                Contact our support and sales team
              </p>
            </div>
            <div className="needhelp-2-btn btn-grad">
              <a href="/contact">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedHelp;
