import React from "react";
import "./about_page.css";
import mainimg from "./aboutpagenew.jpg";
import item1 from "./item-a.jpg";
import item2 from "./item-b.avif";
import pestconrol from "./2.png";
import pestconrol1 from "./aboutnew.jpg";

import item3 from "./item-c.jpg";
import item4 from "./item-d.jpg";
import Footer from "../footer/Footer";

const AboutNewpage = () => {
  return (
    <>
      <div className="about-page-main">
        <div className="about-page-main-img">
          <img src={mainimg} alt="about-page-main-img" />
        </div>
        <div class="px-4 py-5 my-5 text-center">
          <img
            class="d-block mx-auto mb-4"
            src="/docs/5.3/assets/brand/bootstrap-logo.svg"
            alt=""
          />
          <h1 class="display-5 fw-bold text-body-emphasis">
            Universal consultant Hi-Care
          </h1>
          <div class="col-lg-6 mx-auto">
            <p class="lead mb-4 ab-main-p">
              Universal Consultant Hi- Care Pest Control Services in Khanpur,
              Delhi is a recognised in the industry for pest control and
              management services. It was founded in 2014. Initially, this
              enterprise was solely into providing pest control services but
              subsequently it started offering all-encompassing facility
              management services to organisations. Since its inception, it has
              set global standards in pest control and facility management
              services. Striving towards service excellence, this organisation
              ensures the enforcement of guidelines that strictly adhere to all
              rules, regulations and licensing controls. The quality and timely
              services have help them garner a staggering base of retail as well
              as corporate clients.
            </p>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button type="button" class="btn btn-primary btn-lg px-4 gap-3">
                Call Now
              </button>
            </div>
          </div>
        </div>
        <div className="about-page-main-col">
          <div className="item-a">
            <div class="card">
              <img src={item1} class="card-img-top" alt="Bed-Bug-Control-img" />
              <div class="card-body">
                <h5 class="card-title">Bed Bug Control</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div class="card-body">
                <a href="tel:9971539820" class="card-link">
                  <i class="fa-solid fa-phone-volume"></i>&nbsp;Book Now
                </a>
              </div>
            </div>
            <div class="card">
              <img src={item2} class="card-img-top" alt="Termite-Control-img" />
              <div class="card-body">
                <h5 class="card-title">Termite Control</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div class="card-body">
                <a href="tel:9971539820" class="card-link">
                  <i class="fa-solid fa-phone-volume"></i>&nbsp;Book Now
                </a>
              </div>
            </div>
            <div class="card">
              <img
                src={item3}
                class="card-img-top"
                alt="Mosqutio-Control-img"
              />
              <div class="card-body">
                <h5 class="card-title">Mosqutio Control</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div class="card-body">
                <a href="tel:9971539820" class="card-link">
                  <i class="fa-solid fa-phone-volume"></i>&nbsp;Book Now
                </a>
              </div>
            </div>
            <div class="card">
              <img src={item4} class="card-img-top" alt="Rodent-Control-img" />
              <div class="card-body">
                <h5 class="card-title">Rodent Control</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div class="card-body">
                <a href="tel:9971539820" class="card-link">
                  <i class="fa-solid fa-phone-volume"></i>&nbsp;Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="main-page-line-box">

        
        <div className="New-page-left-content">
          <div className="page1-block">
            <div className="page-h1">
              <h1>Services based on the types of pests</h1>
            </div>
            <div className="page-point">
              <ul>
                <li>
                  <p>Mosquito pest control</p>
                </li>
                <li>
                  <p>Cockroach pest control</p>
                </li>
                <li>
                  <p>Rodent control</p>
                </li>
                <li>
                  <p>Lizard pest control</p>
                </li>
                <li>
                  <p>Ant pest control</p>
                </li>
                <li>
                  <p>Termite pest control</p>
                </li>
                <li>
                  <p>Carpet beetle pest control</p>
                </li>
                <li>
                  <p>Bedbug pest control & treatment</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="page-right">
            <img src={pestconrol} alt="pestconrol-img" />
          </div>
        </div>
        <div className="New-page-left-content">
        <div className="page-right">
            <img src={pestconrol1} alt="pestconrol-1-img" />
          </div>
          <div className="page1-block">
            <div className="page-h1">
              <h1>Services based on the types of properties</h1>
            </div>
            <div className="page-point">
              <ul>
                <li>
                  <p>Commercial pest control</p>
                </li>
                <li>
                  <p>Office pest control</p>
                </li>
                <li>
                  <p>Shop and showroom pest control</p>
                </li>
                <li>
                  <p>School & college pest control</p>
                </li>
                <li>
                  <p>Nursing home and hospital pest control</p>
                </li>
                <li>
                  <p>Hotel and restaurant pest control</p>
                </li>
                <li>
                  <p>Godown & warehouse pest control</p>
                </li>
                <li>
                  <p>Farmhouse pest control</p>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutNewpage;
