import React from 'react'
import './count.css'
const Count = () => {
  return (
    <div className="counting">
        <div className="count1">
            <h1>10K+</h1>
            <p> Happy Costumer</p>
        </div>
        <div className="count2">
            <h1>1K+</h1>
            <p>Company Support</p>
        </div>
        <div className="count4">
            <h1>7+</h1>
            <p>Years Experience</p>
        </div>
    </div>
  )
}

export default Count