import React from "react";
import "./header.css";
import logo from './logo.png';
import { NavLink } from "react-router-dom";
export const Header = () => {
  return (
  
    <div className="header">
      <div className="logo">
        <NavLink to="/">
        <img src={logo} alt="Logo-pic" />
        </NavLink>
      </div>
      <nav className="navbar">
        <ul>
          <li>
            <NavLink to="/" className="list ">
           Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className="list ">
             About
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" className="list ">
             Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/" className="list ">
             Blog
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="list ">
            Contact
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="right-header">
      <a href="tel:+91 9971539820"> Book Now</a>
      </div>
      <div className="right-collapse">
        <button
          className="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions"
        >
          <i className="fa-solid fa-bars"></i>
        </button>

        <div
          className="offcanvas offcanvas-start"
          data-bs-scroll="true"
          tabindex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            <div className="logo">
        <NavLink to="/">
        <img src={logo} alt="Logo-pic" />
        </NavLink>
      </div>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body collapse-navbar">
            <ul>
              <li>
                <NavLink to="/" className="list">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className="list">
                About
                </NavLink>
              </li>
              <li>
                <NavLink to="/services" className="list">
                Services
                </NavLink>
              </li>
              <li>
                <NavLink to="/" className="list">
                Blog
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="list">
                Contact
                </NavLink>
              </li>
            </ul>

            <div className="collapse-social-link">
              <div className="callbtn">
                
                  <a href="tel:+91 9971539820"> Call Now</a>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
